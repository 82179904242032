import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import BanerMDD from "../../components/BanerMDD/BanerMDD";

import locales from "../../constants";

const MojaKlonowa = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #AB9263, #BAA173, #C6A770, #907A50, #C7AA73 )",
        icons: "#AB9263",
        navClass: "klonowa",
      }}
      seo={{
        title: "Moja Klonowa",
        headerTitle: "klonowa",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/klonowa_og.png"),
      }}
      // langSwitch={{
      //   langKey: "en",
      //   langSlug: "/en/projects/moja-klonowa/",
      // }}
    >
      <PortfolioHeader name="klonowa" height="276" />
      <div className="klonowa-container">
        <section className="container-fluid klonowa-section-1" id="info">
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>Moja klonowa</h1>
                <ul>
                  <li>Logo</li>
                  <li>Strona internetowa</li>
                  <li>Kampania leadowa</li>
                  <li>Kampania outdorowa</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner">
                <p>
                  Kielecki deweloper, firma Trust Investment, powierzyła nam
                  zadanie opracowania brandingu, materiałów promocyjnych, strony
                  internetowej oraz przeprowadzenia kampanii leadowej dla
                  prestiżowej inwestycji mieszkaniowej.
                </p>
                <p>
                  Moja Klonowa to wyjątkowa na mapie Kielc inwestycja,
                  usytuowana w lokalizacji łączącej bliskość najważniejszych
                  elementów miejskiej infrastruktury z naturalnym otoczeniem
                  zalewu i lasu. Kluczowym elementem wyróżniającym inwestycji
                  jest jednak fakt iż w każdym z mieszkań, w ramach ceny,
                  zainstalowane są rozwiązania smart home pozwalające sterować
                  mieszkaniem z poziomu aplikacji w telefonie.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid klonowa-section-2">
          <div className="row">
            <div className="col-lg-6 col_1">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/klonowa_logo_colors.png")}
                alt=""
              />
              <div className="img_wrapper text-center">
                <img
                  className="img-fluid"
                  src={require("../../assets/img/portfolio/klonowa_logo.svg")}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1 col_2">
              <h3>Logo</h3>
              <div className="row">
                <TrackVisibility once offset={300} className="col-sm-6">
                  {({ isVisible }) => (
                    <>
                      <p>Gold CMYK</p>
                      <h5>
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 20 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 32 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 54 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 16 : 0}
                        />
                      </h5>
                    </>
                  )}
                </TrackVisibility>
                <TrackVisibility once offset={300} className="col-sm-6">
                  {({ isVisible }) => (
                    <>
                      <p>Grey black CMYK</p>
                      <h5>
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 70 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 56 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 44 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 67 : 0}
                        />
                      </h5>
                    </>
                  )}
                </TrackVisibility>
                <TrackVisibility once offset={300} className="col-sm-6">
                  {({ isVisible }) => (
                    <>
                      <p>RGB</p>
                      <h5>
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 171 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 146 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 99 : 0}
                        />
                      </h5>
                    </>
                  )}
                </TrackVisibility>
                <TrackVisibility once offset={300} className="col-sm-6">
                  {({ isVisible }) => (
                    <>
                      <p>RGB:</p>
                      <h5>
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 70 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 56 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 44 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 67 : 0}
                        />
                      </h5>
                    </>
                  )}
                </TrackVisibility>
                <div className="col-sm-6">
                  <p>HEX</p>
                  <h5>AB9263</h5>
                </div>
                <div className="col-sm-6">
                  <p>HEX</p>
                  <h5>19252F</h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid klonowa-section-3">
          <div className="row">
            <div className="col-lg-6">
              <div className="inner">
                <h3>Logo</h3>
                <p>
                  Nazwa inwestycji była już opracowana, pozostało nam więc
                  podejść do dość mocno określonego w briefie kierunku w sposób
                  nieco inny niż sąsiadujące z Moją Klonową Inwestycje.
                  Wyzwaniem było to, aby w całym brandingu odróżnić się od
                  konkurencyjnych ofert, których nazwy były dość zbliżone a
                  bazowa kolorystyka niemal identyczna.
                </p>
                <p>
                  Do kolorów podstawowych, zaprojektowaliśmy 3 dodatkowe, które
                  nie tylko komunikują główne cechy inwestycji, ale również
                  wyróżniają ją od inwestycji znajdujących się z jej
                  bezpośrednim otoczeniu.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                className="img-fluid w-100"
                src={require("../../assets/img/portfolio/klonowa_main_1.png")}
                alt=""
              />
            </div>
          </div>
        </section>
        <section className="container-fluid klonowa-section-4">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="scroll_wrapper">
                <div className="img_wrapper">
                  <img
                    src={require("../../assets/img/portfolio/klonowa_entire_page.jpg")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid klonowa-section-5">
          <div className="row">
            <div className="col-lg-5">
              <div className="inner">
                <h3>Strona internetowa</h3>
                <p>
                  Mechanizmy na stronach inwestycji mieszkaniowych są w zasadzie
                  ustandaryzowane, dodaliśmy jednak kilka dodatkowych rozwiązań
                  usprawniających użytkownikom zapoznanie się z inwestycją:
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <video width="100%" loop autoPlay muted data-setup="{}">
                <source
                  src={require("../../assets/video_portfolio/klonowa_video_1.mp4")}
                  type="video/mp4"
                />
                <source
                  src={require("../../assets/video_portfolio/klonowa_video_1.webm")}
                  type="video/webm"
                />
              </video>
            </div>
          </div>
        </section>
        <section className="container-fluid klonowa-section-6">
          <h4>Dodawanie mieszkań do schowka</h4>
          <div className="row">
            <div className="col-sm-12 col-lg-8 d-flex">
              <video width="100%" loop autoPlay muted data-setup="{}">
                <source
                  src={require("../../assets/video_portfolio/klonowa_video_2.mp4")}
                  type="video/mp4"
                />
                <source
                  src={require("../../assets/video_portfolio/klonowa_video_2.webm")}
                  type="video/webm"
                />
              </video>
            </div>
            <img
              className="first-img img-fluid"
              src={require("../../assets/img/portfolio/klonowa_main_3.png")}
              alt=""
            />
            <img
              className="last-img img-fluid"
              src={require("../../assets/img/portfolio/klonowa_main_4.png")}
              alt=""
            />
          </div>
        </section>
        <section className="container-fluid klonowa-section-7 no-gutters">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-lg-9 video-wrapper">
              <h4 className="text-right">Działanie Smart Home</h4>
              <video width="100%" loop autoPlay muted data-setup="{}">
                <source
                  src={require("../../assets/video_portfolio/klonowa_video_4.mp4")}
                  type="video/mp4"
                />
                <source
                  src={require("../../assets/video_portfolio/klonowa_video_3.webm")}
                  type="video/webm"
                />
              </video>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/klonowa_main_5.png")}
                alt=""
              />
            </div>
            <div className="col-sm-12 col-lg-5">
              <h4>Kalkulator kredytowy</h4>
              <video width="100%" loop autoPlay muted data-setup="{}">
                <source
                  src={require("../../assets/video_portfolio/klonowa_video_3.mp4")}
                  type="video/mp4"
                />
                <source
                  src={require("../../assets/video_portfolio/klonowa_video_4.webm")}
                  type="video/webm"
                />
              </video>
              <div className="inner d-flex justify-content-end">
                <p>
                  Stronę inwestycji wykonaliśmy w technologii Gatsby.js, dzięki
                  czemu działa ona szybko oraz jest wyjątkowo bezpieczna.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid klonowa-section-8">
          <div className="row d-flex justify-content-end">
            <div className="img-container">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/klonowa_main_6.png")}
                alt=""
              />
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/klonowa_main_7.png")}
                alt=""
              />
            </div>
          </div>
        </section>
        <section className="container-fluid klonowa-section-9">
          <div className="row">
            <div className="col-lg-4 offset-lg-1">
              <div className="inner">
                <h3>Kampania leadowa</h3>
                <p>
                  Odpowiadaliśmy również za stworzenie i prowadzenie kampanii,
                  której celem było pozyskiwanie wartościowych leadów. Na jej
                  potrzeby zaprojektowaliśmy między innymi banery
                  remarketingowe.
                </p>
              </div>
              <img
                src={require("../../assets/img/portfolio/klonowa_main_9.png")}
                className="img_main_3"
                alt=""
              />
            </div>
            <div className="col-lg-5 offset-lg-2">
              <img
                src={require("../../assets/img/portfolio/klonowa_main_8.png")}
                className="img-fluid img_main_4"
                alt=""
              />
            </div>
          </div>
        </section>
        <section className="container-fluid klonowa-section-10 no-gutters">
          <h3 className="text-right">Katalog</h3>
          <div className="bg">
            <img
              src={require("../../assets/img/portfolio/klonowa_main_10.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </section>
        <section className="container-fluid klonowa-section-11">
          <div className="row">
            <div className="col-2">
              <h3>
                Karty
                <br />
                mieszkań
              </h3>
            </div>
            <div className="col-12 col-sm-10">
              <img
                src={require("../../assets/img/portfolio/klonowa_main_11.png")}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </section>
        <section className="container-fluid klonowa-section-12 text-center no-gutters">
          <h3 className="text-center">Outdoor</h3>
          <img
            src={require("../../assets/img/portfolio/klonowa_main_12.png")}
            className="img-fluid w-100"
            alt=""
          />
          <img
            src={require("../../assets/img/portfolio/klonowa_main_13.png")}
            className="img-fluid"
            alt=""
          />
        </section>
      </div>

      <BanerMDD />

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default MojaKlonowa;
